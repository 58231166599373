export const environment = {
  production: false,
  apiUrl: 'https://api.staging.adldelivery.com/api',
  reportViewUrl: '',
  smartyStreetsKey: '8288206142130698',
  dynamsoftKey: 'f0068WQAAANog3+5h9Hb2MnfIzVtMNx634Hof2jCqfXSCJYzcLYIsxJYEmRaBJGJIOZqpGqO8U8iB83avg1pt0r0t5Bewvcs=',
    dynamsoft: {
      twainKey: 'f0068WQAAANog3+5h9Hb2MnfIzVtMNx634Hof2jCqfXSCJYzcLYIsxJYEmRaBJGJIOZqpGqO8U8iB83avg1pt0r0t5Bewvcs=',
      cameraKey: 'f00869QAAACHXlkBPfB/tatqwwD5gUuAjsDX0W/L2Zkd/aDfwksqUJFo5J1Fq6yCok/PG/u681OoehGnhDliQgnB7AFfFU1nmstPuC+HjzaRY5DDzExi3MEE='
    },
  vehicleApiUrl: 'https://vpic.nhtsa.dot.gov/api/vehicles',
  userManagerSettings: {
    authority: 'https://auth.staging.adldelivery.com',
      client_id: 'adlstagingsite',
      redirect_uri: 'https://www.staging.adldelivery.com/auth-callback',
      post_logout_redirect_uri: 'https://www.staging.adldelivery.com',
      response_type: 'code',
      response_mode: 'query',
      scope: 'openid profile full_api_scope',
      loadUserInfo: true,
      monitorSession: true,
      checkSessionInterval: 2000,
      revokeAccessTokenOnSignout: true
  }
};
